import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";
import { debounce } from "lodash";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: grey[900],
  },

  "& .MuiRating-pristine": {
    outline: "transparent",
    position: "unset",
  },
});

const FONT_SIZE = "6.5rem";

const customIcons = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon
        sx={{ fontSize: FONT_SIZE }}
        htmlColor="#FF0000"
      />
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: (
      <SentimentDissatisfiedIcon
        sx={{ fontSize: FONT_SIZE }}
        htmlColor="#FFAA00"
      />
    ),
    label: "Dissatisfied",
  },
  3: {
    icon: (
      <SentimentNeutralIcon sx={{ fontSize: FONT_SIZE }} htmlColor="#C4C4C4" />
    ),
    label: "Neutral",
  },
  4: {
    icon: (
      <SentimentSatisfiedAltIcon
        sx={{ fontSize: FONT_SIZE }}
        htmlColor="#22A3DC"
      />
    ),
    label: "Satisfied",
  },
  5: {
    icon: (
      <SentimentVerySatisfiedIcon
        sx={{ fontSize: FONT_SIZE }}
        htmlColor="#64C831"
      />
    ),
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value]?.icon}</span>;
}

function RadioGroupRating({ rating, setSelectedRating, localized }) {
  return (
    <Box>
      <StyledRating
        name="customized-rating"
        precision={1}
        defaultValue={rating}
        IconContainerComponent={IconContainer}
        getLabelText={(value) => customIcons[value]?.label || ""}
        highlightSelectedOnly
        onChange={debounce(
          (_event, newValue) => setSelectedRating(newValue),
          450
        )}
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "2rem",
          padding: "1rem 0",
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span className="fs-1 fw-bold">{localized.Unsatisfied}</span>
        <span className="fs-1 fw-bold">{localized.HighlySatisfied}</span>
      </Box>
    </Box>
  );
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

RadioGroupRating.propTypes = {
  rating: PropTypes.number.isRequired,
  setSelectedRating: PropTypes.func.isRequired,
  localized: PropTypes.object.isRequired,
};

export default RadioGroupRating;
