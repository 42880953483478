import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OrderSummary from './OrderSummary';
import Shipments from './shipments/Shipments';
import OrderItems from './OrderItems';
import OrderPayment from './OrderPayment';
import Api from '../../services/Api';
import Loading from '../Loading';
import HistoryItemComments from '../HistoryItemComments';
import { withSelectedCustomer } from '../../hooks/withSelectedCustomer';
import { withSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import OrderService from '../../services/OrderService';
export class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDetails: null,
            fetching: true,
            order: null,
            taxTotal:0.00
        }

    }
    
    componentDidMount() {
        if (this.props.orderId && this.props.orderCompany && this.props.docType)
            Api.fetch(`/api/orders/${this.props.orderId}/${this.props.orderCompany}/${this.props.docType}`).then(this.headerDetailsLoaded);
    }

    headerDetailsLoaded = (response) => {        
        this.setState({ fetching: false, order: response.order, orderDetails: response.orderDetails });
        OrderService.getOrderLineTaxes(this.props.orderId).then((taxes) => {
            var total = 0;
            if (taxes.length != 0 && taxes) {
                var filteredTaxes = taxes.filter((x) => x.orderType == this.state.order.orderType);
                

                for (const line of filteredTaxes) {
                    total = line.extendedTaxAmount + total;
                    total.toFixed(2);
                }                
            }
            this.setState({ taxTotal: total });
        })
    }


    getShippingAddress = (order) => {
        if (order.dropShip.name) {
            return order.dropShip;
        }
        else {
            return order.shipTo
        }
    }

    
    render() {
        const { order, orderDetails, taxTotal } = this.state;
        const { selectedCustomer } = this.props;
        const localized = this.props.salesHistoryConfig.labels;
        const orderDetailsPDF = this.props.salesHistoryConfig.settings.OrderDetailsPDF === true;
        const shareViaWhatsApp = this.props.salesHistoryConfig.settings.ShareViaWhatsApp === true;
        const showComments = this.props.salesHistoryConfig.settings.ShowOrderHistoryComments === true;
        const hideOrderTaxLine = this.props.salesHistoryConfig.settings.HideOrderTaxLine === true;
        return (
            <div className="fele-order-history-details">
                {
                    this.state.fetching &&
                    <Loading type="brand" />
                }
                {
                    !this.state.fetching &&
                    <React.Fragment>
                        <OrderSummary localized={localized} order={order} orderDetailsPDF={orderDetailsPDF} selectedCustomer={selectedCustomer} shareViaWhatsApp={shareViaWhatsApp} />
                        {
                            showComments &&
                            <HistoryItemComments localized={localized} comments={order.comments || ""} />
                        }
                        {
                            !orderDetails &&
                            <div className="no-results">
                                {localized.NoResultsFound}
                            </div>
                        }
                        {
                            selectedCustomer && orderDetails &&
                                <React.Fragment>
                                <div className="order-heading2 heading2">{localized.ItemsToBeShipped}</div>
                                {
                                   
                                            orderDetails.lineItems &&
                                            <OrderItems items={orderDetails.lineItems}  selectedCustomer={selectedCustomer} dateDisplayFormat={this.props.StorefrontConfig.settingsList.DateDisplayFormat} localized={localized} />
                                }
                                {
                                    orderDetails.shipments &&
                                            <Shipments shipments={orderDetails.shipments} selectedCustomer={selectedCustomer} shippingAddress={this.getShippingAddress(order)} localized={localized} />
                                        }
                                        <OrderPayment currency={order.currency} additionalTaxes={taxTotal} orderDetails={orderDetails} billTo={order.billTo} selectedCustomer={selectedCustomer} hideOrderTaxLine={hideOrderTaxLine} localized={localized} />
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        );
    }
}

Order.propTypes = {
    selectedCustomer: PropTypes.object
}

export default connect(
    null,
    null
)(withSalesHistoryConfig(withStorefrontConfig(withSelectedCustomer(Order))));