import React from "react";
import PropTypes from "prop-types";

const LoyaltyIDInput = ({
  loyaltyIDSubmit,
  needsValidation,
  loyalityIDWarning: { isNumber, loyaltyID },
  setLoyaltyIDSubmit,
  handleEnter,
  localized,
}) => {
  const inputClass =
    needsValidation && loyaltyIDSubmit.length > 0
      ? isNumber && (loyaltyID || loyaltyID === null)
        ? "is-valid"
        : "is-invalid"
      : "";

  return (
    <>
      <input
        data-cy="loyaltyid-submit"
        type="text"
        className={`form-control ${inputClass}`}
        onChange={(e) => setLoyaltyIDSubmit(e)}
        onKeyUp={handleEnter}
        value={loyaltyIDSubmit}
        maxLength={8}
      />

      {!isNumber && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {localized.LoyaltyIDNumericOnly}
        </div>
      )}

      {needsValidation &&
        loyaltyIDSubmit.length > 0 &&
        loyaltyID !== null &&
        loyaltyID === false && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {localized.LoyaltyIDNotFound}
          </div>
        )}
    </>
  );
};

LoyaltyIDInput.propTypes = {
  loyaltyIDSubmit: PropTypes.string,
  needsValidation: PropTypes.bool,
  loyalityIDWarning: PropTypes.shape({
    isNumber: PropTypes.bool,
    loyaltyID: PropTypes.bool,
  }),
  setLoyaltyIDSubmit: PropTypes.func,
  handleEnter: PropTypes.func,
  localized: PropTypes.object,
};

export default LoyaltyIDInput;
