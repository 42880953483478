import React, { useEffect, useState } from 'react';
import StatusHelper from '../../helpers/StatusHelper';
import Calendar from '../form-controls/Calendar';
import { MultiSelect } from "react-multi-select-component";

const OrdersFilter = (props) => {
    const isEnglish = props.localized.Last90Days == '90 days';
    const lineStatusFilters = props.availableStatuses.filter(x=>x.isQuoteStatus === false && x.shown ===true).map(x=>x.description);
    const handleEnter = (e) => {
        if (e.key === 'Enter') props.fetchOrders();
    };   

    const getSearchTitle = () => {
        return props.historyByLine ? props.localized.LinesSearchInputLabel : props.localized.SearchForOrders;
    };

    const handleSelectChange = (options) => {
        props.setStatus(options);
    };

    const options = props.historyByLine
        ? lineStatusFilters.map((item, index) => ({
            value: item,
            label: StatusHelper.formatStatus(item, props.localized),
        }))
        : [
            { value: 'BeingProcessed', label: props.localized.BeingProcessed },
            { value: 'InProgress', label: props.localized.Shipped },
        ];
    const defaults = props.status 
        ? props.status.map((item, index) => ({
            value: item,
            label: StatusHelper.formatStatus(item, props.localized),
        }))
        : [
            { value: 'BeingProcessed', label: props.localized.BeingProcessed },
            { value: 'InProgress', label: props.localized.Shipped },
        ];

    const customSelectStyles = {
        option: (provided) => ({
            ...provided,
            fontSize: '1.5rem'
        }),
        control: (base) => ({
            ...base,
            fontSize: '1.4rem',
            minHeight: 35,
        }),       
    };

    return (
      <div>
            <div className="container-fluid">
                <div className="row gy-2">
                    <div className="col-xl-2 col-lg-2 d-flex" >
                        <div style={{ 'max-width': '100%', 'minWidth': '100%' }}>
                            <div className="label">{props.localized.OrderStatus}</div>
                            <MultiSelect
                                id={'ddlSetStatus'}                               
                                styles={customSelectStyles}
                                isMulti
                                options={options}
                                onChange={(selectedOption) => handleSelectChange(selectedOption)}
                                placeholder={props.localized.SelectStatus}
                                value={defaults}
                                hasSelectAll
                                disableSearch
                                overrideStrings={{
                                    "allItemsAreSelected": props.localized.AllSelected,
                                    "selectSomeItems": props.localized.SelectStatus,
                                    "selectAll": props.localized.SelectAll
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                        <div className="label">{props.localized.FromDate}</div>
                        <div className="fele-content">
                            <div className="input-wrapper">
                                <Calendar
                                    date={props.fromFullDate}
                                    minDate={new Date().setDate(new Date().getDate() - 730)}
                                    callBackFunction={props.setFromDate}
                                    showIcon={true}
                                    dateFormat={props.dateFormat}
                                    localized={props.localized} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                        <div className="label">{props.localized.ToDate}</div>
                        <div className="input-wrapper">
                            <Calendar
                                date={props.toFullDate}
                                minDate={new Date().setDate(new Date().getDate() - 730)}
                                callBackFunction={props.setToDate}
                                dateFormat={props.dateFormat}
                                showIcon={true} />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="label">{getSearchTitle()}</div>
                        <div className="form-group">
                            <input
                                data-cy="order-search"
                                type="text"
                                placeholder={props.localized.Search}
                                onChange={props.setNumber}
                                onKeyPress={handleEnter}
                                value={props.numberFilter} />
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <label></label>
                        <div className="row">
                            <div className="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-6 gx-2 gy-2">
                                <div className="button-wrapper small float-end">
                                    <button data-cy="order-submit-search" className="button search-button button-alt" onClick={props.fetchOrders}>
                                        {props.localized.Search}
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-3 col-md-3 col-sm-3 col-xs-6 gx-2 gy-2">
                                <div className="button-wrapper small float-end">
                                    <button data-cy="order-submit-search" className="button search-button button-alt" style={{ width: "fit-content", height: "fit-content", padding: "3px", whiteSpace: "nowrap" }} onClick={props.resetFilters}>
                                    {props.localized.ResetFilters}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><div className="fele-order-history-filter">
                <div className="seperator"></div>
            </div>
        </div>
    );
};

export default OrdersFilter;
