import { addBusinessDays, format, getHours, isDate, differenceInSeconds, compareAsc, sub, parse, addDays } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export default class DateHelper {
    static determineRequestDate(newRequestDate, oldRequestDate) {
        var todaysDate = new Date(Date.now()).setHours(0, 0, 0, 0);
        if (isDate(newRequestDate) && newRequestDate.setHours(0, 0, 0, 0) >= todaysDate) {
            return this.getUTCDate(newRequestDate);
        }
        else if (isDate(oldRequestDate) && oldRequestDate.setHours(0, 0, 0, 0) >= todaysDate) {
            return this.getUTCDate(oldRequestDate);
        }
        else {
            return this.getUTCDate(new Date(todaysDate));
        }
    }
    static getHours(date) {
        return getHours(date);
    }
    static determineFutureDate(itemDate) {
        var todaysDate = new Date(Date.now()).setHours(0, 0, 0, 0);
        if (isDate(itemDate) && itemDate.setHours(0, 0, 0, 0) >= todaysDate) {
            return new Date(itemDate).toISOString();
        }
        else {
            return new Date(todaysDate).toISOString();
        }
    }

    static getNewDate() {
        return new Date(Date.now());
    }

    static addShipDays(days) {
        return new Date(Date.now() + days * 24 * 60 * 60 * 1000)
    }

    static checkDateIsString(date, dateFormat) {
        if (typeof date === 'string' || date instanceof String) {
            // If date format is known in the component, use that to parse
            if (dateFormat) {
                const newDate = parse(date, dateFormat, new Date());
                if (newDate == 'Invalid Date') return new Date(date);
                else return newDate;
            }
            else {
                // string formats from various places in the app that may need to be converted to a Date
                const formats = ['yyyy-MM-dd', 'yyyyMMdd', 'EEEE MM/dd', 'yyy-MMM-dd', 'MM/dd/yyyy hh:mm a'];
                let i = 0;
                while (i < formats.length) {
                    const tryDate = parse(date, formats[i], new Date());
                    if (tryDate != 'Invalid Date') {
                        return tryDate;
                    }
                    i++;
                }
                return new Date(date);
            }
        }
        return date;
    }

    static addBusinessDays(date, days, dateFormat) {
        if (date && date !== undefined) {
            date = this.checkDateIsString(date, dateFormat);
            return addBusinessDays(date, days);
        }
        else return undefined;
    }

    static format(date, dateFormat, adjustTimezone = false) {
        if (date && date !== undefined) {
            const dateObj = this.checkDateIsString(date, dateFormat);

            if (!adjustTimezone)
                return format(dateObj, dateFormat);

            if ((typeof date === 'string' || date instanceof String) && date.includes('T')) return format(dateObj, dateFormat);
            else return format(dateObj.valueOf() - (dateObj.getTimezoneOffset() * 60000), dateFormat);
        }
        else return undefined;
    }

    // unlike date-fns.isDate this function also returns true if a string passed in could be a valid date, 
    // not just if it's currently an instance of Date
    static isValidDate(date) {
        const checkDate = this.checkDateIsString(date);
        return isDate(checkDate) && !isNaN(checkDate);
    }

    static differenceInSeconds(date1, date2) {
        if (date1 && date1 !== undefined && date2 && date2 !== undefined) {
            date1 = this.checkDateIsString(date1);
            date2 = this.checkDateIsString(date2);
            return differenceInSeconds(date1, date2);
        }
        else return undefined;
    }

    // Compares the two dates and returns 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
    static compareDates(date1, date2) {
        if (date1 && date1 !== undefined && date2 && date2 !== undefined) {
            date1 = this.checkDateIsString(date1);
            date2 = this.checkDateIsString(date2);
            return compareAsc(date1, date2);
        }
        else return undefined;
    }

    // ex. interval { months: 36 }
    static subtract(date, subtractInterval) {
        date = this.checkDateIsString(date);
        return sub(date, subtractInterval);
    }

    static addDays(date, addInterval) {
        date = this.checkDateIsString(date);
        var newDate = addDays(date, addInterval);
        return newDate
    }

    static getUTCDate(dateToConvert) {
        return zonedTimeToUtc(dateToConvert, Intl.DateTimeFormat({ timeZone: 'UTC' }).resolvedOptions().timeZone);
    }
    static getStringDate(date) {
        if (date) {
            return (date.getFullYear().toString()
                + '-' + ("0" + (date.getMonth() + 1)).slice(-2)
                + '-' + date.getDate().toString())
        }
        else return '';
    }
}