import * as React from 'react';
import { useState, useEffect } from 'react';
import storefrontService from '../services/Storefront';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import Localization from '../services/Localization';
import * as Components from '../resources/Components';

const HelpConfigContext = React.createContext();

function HelpConfigProvider({ children, ...props }) {
    const [labels, setLabels] = useState({});
    const { locale } = useStorefrontSettings();
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);

    useEffect(() => {
        Localization.getComponentLabels(Components.HELP, locale, storefrontNumber)
            .then(res => {
                setLabels(res);
            })
    }, [locale]);

    return (
        <HelpConfigContext.Provider
            value={{
                labels
            }}
        >
            {children}
        </HelpConfigContext.Provider >
    );
}

function useHelpConfig() {
    const context = React.useContext(HelpConfigContext);
    if (context === undefined) {
        throw new Error('HelpConfigContext must be used within a HelpConfigContext');
    }
    return context;
}

function withHelpConfig(Component) {
    return function WrappedComponent(props) {
        const HelpConfig = useHelpConfig();
        return <Component {...props}
            HelpConfig={HelpConfig}
        />;
    }
}

export { HelpConfigProvider, useHelpConfig, withHelpConfig }