// High Level Components
export const PRODUCT = '877D03B2-5E4B-4A65-B095-110E7F27D409';
export const NAVIGATION = '4C10A856-A5B3-4446-95C9-3CE24E0B2D6C';
export const INVOICE_HISTORY = '9246C307-50C4-45C9-91A3-43E9B3AA91A6';
export const EDIT_QUOTE = 'FE493DA8-75A1-4D00-8C18-7AD336D2C6C3';
export const CART = '5D76398D-B5AE-403F-88A8-7CD4202EFAFC';
export const SALES_HISTORY = '1EA18D6F-3EBB-490F-9FE4-B4C2C2FCB485';
export const ACCOUNT_SUMMARY = 'A519387F-8425-46F3-B077-BD530E2F6FCD';
export const WARRANTY = 'ACFF5173-E929-43C2-BDBF-5F7E094AFAD0';
export const DASHBOARD = '7CA904DE-9019-4F52-A0A1-57AF5D4BDCED';
export const FEEDBACK = '93807980-F08E-421A-B35C-B03708826DBB';
export const HELP = '4CC0C8E7-81E4-4D6E-8E53-9291AC13BF40';
export const USERS = '08CBB253-BFC2-4206-BA55-EEC7E690F77E';
export const STOREFRONTADMINISTRATION = 'CA50631A-C5E7-4F16-9FAD-295D867AE25F'