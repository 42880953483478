import React, { useState } from "react";
import PropTypes from "prop-types";
import ActionButton from "../shared/button/ActionButton";
import Rating from "./Rating";
import CloseIcon from "@mui/icons-material/Close";
import Api from "../../services/Api";
import { useSelector } from "react-redux";
import Notifications from "../../services/Notifications";
import { CSSTransition } from "react-transition-group";
import useDelayedRender from "../../hooks/useDelayedRender";
import useSetCookie from "../../hooks/useSetCookie";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const titleStyles = {
  fontWeight: 700,
  fontSize: "2.6rem",
  fontFamily: "Gotham XNarrow SSm A, Gotham XNarrow SSm B",
};

const checkboxLabelStyles = {
  fontSize: "2rem",
  fontFamily: "Gotham XNarrow SSm A, Gotham XNarrow SSm B",
};

const checkboxStyles = {
  "& .MuiSvgIcon-root": { fontSize: 28 },
};

const checkboxes = [
  { name: "missingInformation", labelKey: "MissingInformation" },
  { name: "incorrectInformation", labelKey: "IncorrectInformation" },
  { name: "notEnoughDetail", labelKey: "NotEnoughDetail" },
  { name: "outOfDateInformation", labelKey: "OutOfDateInformation" },
];

export default function OrderSurvey({ localized, surveyCookieExpiryDays }) {
  const surveyDismissed = document.cookie.includes(
    "FE_Direct_Survey_Dismissed=true"
  );

  const [open, setOpen] = useState(true);
  const [selectedRating, setSelectedRating] = useState(null);
  const [errors, setErrors] = useState({});

  const [formState, setFormState] = useState({
    selectedRating: 0,
    missingInformation: false,
    incorrectInformation: false,
    notEnoughDetail: false,
    outOfDateInformation: false,
    other: false,
    specify: "",
  });

  const store = useSelector((state) => state);
  const { shouldRender } = useDelayedRender(1500);
  const { setCookie } = useSetCookie();

  const validateOtherText = (value) => {
    return value.trim() === "";
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }

    setOpen(false);
    setErrors({});
  };

  const handleChange = (value) => {
    setFormState((prev) => ({ ...prev, specify: value }));
    const isError = validateOtherText(value);

    setErrors((prev) => ({
      ...prev,
      specify: isError,
    }));
  };

  const handleCheckboxChange = (name, checked) => {
    setFormState((prev) => ({ ...prev, [name]: checked }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const otherTextError = validateOtherText(formState.specify);

    if (formState.other && otherTextError) {
      setErrors({ specify: otherTextError });

      return;
    }

    const response = await Api.fetch("api/survey/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: store.user.currentUser.id,
        customerId: store.user.currentUser.selectedCustomerId,
        rating: formState.selectedRating,
        missingInformation: formState.missingInformation,
        incorrectInformation: formState.incorrectInformation,
        notEnoughDetail: formState.notEnoughDetail,
        outOfDateInformation: formState.outOfDateInformation,
        other: formState.other,
        OtherText: formState.specify,
      }),
    });

    if (!response.ok) {
      Notifications.error(localized.FailedSaveSurvey);

      return;
    }

    handleClose();
    Notifications.message(localized.SavedSurvey);

    setCookie(
      "FE_Direct_Survey_Dismissed",
      "true",
      surveyCookieExpiryDays,
      "fe-direct.com"
    );
  };

  const updateRating = (rating) => {
    if (rating === null) {
      rating = formState.selectedRating;
    }

    setSelectedRating(rating);
    setFormState((prev) => ({
      ...prev,
      selectedRating: rating,
      other: rating === 1 || rating === 2,
      specify: "",
    }));

    if (rating >= 3 && rating <= 5) {
      saveSurvey(rating);
    }
  };

  // ToDo: Remove this function and use the handleFormSubmit function (Duplicate code)
  const saveSurvey = async (rating) => {
    const response = await Api.fetch("api/survey/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: store.user.currentUser.id,
        customerId: store.user.currentUser.selectedCustomerId,
        rating: rating,
      }),
    });

    if (!response.ok) {
      Notifications.error(localized.FailedSaveSurvey);

      return;
    }

    Notifications.message(localized.SavedSurvey);

    setCookie(
      "FE_Direct_Survey_Dismissed",
      "true",
      surveyCookieExpiryDays,
      "fe-direct.com"
    );
  };

  return (
    <Dialog
      open={shouldRender && open && !surveyDismissed}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        component: "form",
        onSubmit: handleFormSubmit,
      }}
    >
      <DialogTitle sx={{ paddingTop: "2.5rem" }}>
        <Typography
          variant="inherit"
          sx={{
            maxWidth: "38ch",
            ...titleStyles,
            ...(selectedRating
              ? { display: "flex", flexDirection: "column" }
              : {}),
          }}
        >
          {!selectedRating && localized.SurveyTitle}

          {selectedRating === 1 || selectedRating === 2
            ? localized.TellUsWhy
            : null}
        </Typography>
      </DialogTitle>

      <IconButton
        aria-label={localized.Close}
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 4,
          top: 4,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon fontSize="large" />
      </IconButton>

      <DialogContent
        sx={{
          paddingTop: 0,
          paddingBottom: "4rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!selectedRating ? (
          <Rating
            localized={localized}
            setSelectedRating={updateRating}
            rating={formState.selectedRating}
          />
        ) : (
          <div className="d-flex flex-column align-items-center w-100 pt-4">
            {/* Temp Remove the checkbox questionaire */}
            {/* {false && (
              <FormGroup>
                {checkboxes.map((checkbox) => (
                  <FormControlLabel
                    key={checkbox.name}
                    control={
                      <Checkbox
                        name={checkbox.name}
                        sx={checkboxStyles}
                        checked={formState[checkbox.name]}
                        onChange={(event) =>
                          handleCheckboxChange(
                            checkbox.name,
                            event.target.checked
                          )
                        }
                      />
                    }
                    label={localized[checkbox.labelKey]}
                    sx={{ "& .MuiFormControlLabel-label": checkboxLabelStyles }}
                  />
                ))}

                <FormControlLabel
                  control={
                    <Checkbox
                      name="other"
                      sx={checkboxStyles}
                      checked={formState.other}
                      onChange={(event) => {
                        handleCheckboxChange("other", event.target.checked);
                      }}
                    />
                  }
                  label={localized.OtherOption}
                  sx={{ "& .MuiFormControlLabel-label": checkboxLabelStyles }}
                />
              </FormGroup>
            )} */}

            {selectedRating >= 3 && selectedRating <= 5 ? (
              <span
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  ...titleStyles,
                }}
              >
                {localized.ThankYouFeedback}
              </span>
            ) : null}

            <CSSTransition
              in={selectedRating === 1 || selectedRating === 2}
              timeout={300}
              classNames="fadeTransition"
              unmountOnExit
            >
              <>
                <textarea
                  name="specify"
                  className={`form-control ${
                    errors.specify ? "is-invalid" : ""
                  }`}
                  style={{
                    resize: "none",
                    height: "130px",
                    fontSize: "1.8rem",
                    marginTop: "3rem",
                  }}
                  onChange={(event) =>
                    event.target.value.length <= 255 &&
                    handleChange(event.target.value)
                  }
                  value={formState.specify}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      color: "#dc3545",
                      alignSelf: "flex-start",
                      margin: 0,
                      visibility: errors.specify ? "visible" : "hidden",
                    }}
                  >
                    {localized.PleaseSpecify}
                  </p>

                  <Typography
                    variant="h6"
                    sx={{
                      paddingTop: "0.5rem",
                      fontFamily: "Gotham XNarrow SSm A, Gotham XNarrow SSm B",
                    }}
                  >
                    {formState.specify.length} / 255
                  </Typography>
                </div>
              </>
            </CSSTransition>
          </div>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "2rem",
          paddingBottom: "2rem",
        }}
      >
        {!(selectedRating >= 3 && selectedRating <= 5) ? (
          <>
            {selectedRating ? (
              <ActionButton
                type="button"
                variant="gray-outline"
                text={localized.ButtonText_Back}
                onClick={() => setSelectedRating(null)}
              />
            ) : null}

            <ActionButton
              type={selectedRating ? "submit" : "button"}
              variant="blue-solid"
              text={selectedRating ? localized.Send : localized.Dismiss}
              onClick={!selectedRating ? handleClose : null}
            />
          </>
        ) : null}

        {selectedRating >= 3 && selectedRating <= 5 ? (
          <ActionButton
            type="button"
            variant="blue-solid"
            text={localized.Dismiss}
            onClick={handleClose}
          />
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

OrderSurvey.propTypes = {
  localized: PropTypes.object,
  surveyCookieExpiryDays: PropTypes.number,
};
