import React from "react";
import PropTypes from "prop-types";

const SerialNumberInput = ({
  serialNumberSubmit,
  serialNumberWarning,
  serialNumberErrors,
  pioneerBrand,
  needsValidation,
  localized,
  setSerialNumberSubmit,
  handleEnter,
  validateSerialNumber,
  itemNumberSubmit,
  illegitimateSerialNumber,
}) => {
  const inputClass =
    pioneerBrand &&
    pioneerBrand.shown === true &&
    pioneerBrand.required === false
      ? ""
      : needsValidation
      ? illegitimateSerialNumber ||
        serialNumberWarning ||
        serialNumberSubmit.length < 1 ||
        serialNumberErrors
        ? "is-invalid"
        : "is-valid"
      : "";

  const getErrorMessage = () => {
    const errorConditions = [
      [serialNumberWarning, localized.DuplicateSerialSubmitWarning],
      [
        serialNumberSubmit.length === 0,
        localized.EmptySerialSubmitWarning || "",
      ],
      [serialNumberErrors, localized.InvalidSerialSubmitWarning || ""],
      [illegitimateSerialNumber, localized.IllegitimateSerialNumber || ""],
    ];

    const error = errorConditions.find(([condition]) => condition);
    return error ? error[1] : localized.AlphanumericSubmitWarning;
  };
  return (
    <>
      <input
        data-cy="serialnumber-submit"
        type="text"
        onChange={(e) => setSerialNumberSubmit(e)}
        onBlur={(e) => validateSerialNumber(itemNumberSubmit, e.target.value)}
        className={`form-control ${inputClass}`}
        onKeyUp={handleEnter}
        value={serialNumberSubmit}
        maxLength={30}
        required
      />

      <div className="invalid-feedback">{getErrorMessage()}</div>
    </>
  );
};

SerialNumberInput.propTypes = {
  serialNumberSubmit: PropTypes.string,
  serialNumberWarning: PropTypes.bool,
  serialNumberErrors: PropTypes.bool,
  shown: PropTypes.bool,
  needsValidation: PropTypes.bool,
  localized: PropTypes.object,
  setSerialNumberSubmit: PropTypes.func,
  handleEnter: PropTypes.func,
  validateSerialNumber: PropTypes.func,
  itemNumberSubmit: PropTypes.string,
  illegitimateSerialNumber: PropTypes.bool,
};

export default SerialNumberInput;
