import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box  from '@mui/material/Box';
import Notifications from '../../services/Notifications';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { DataGrid, GridToolbarContainer,GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid'; 
import Paper from '@mui/material/Paper';
import { InputLabel, Select, MenuItem } from '@mui/material';

export const LabelAdministration = () => {
    const [translationLocaleData, setTranslationLocaleData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [openUpdate, setOpenUpdate] = useState(false);
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const storeData = useStorefrontSettings();
    const responseMessages = {
        "GenericFailure": "An error occurred while attempting to update the label. Please try again later.",
        "UpdateSuccess": "Label successfully updated."
    };

    useEffect(() => {
        getTranslationLocales();
    }, []);   

    const customToolbar =()=> {
        return (
            <GridToolbarContainer>
            <InputLabel id="select-language-label">Select a Language...</InputLabel>
                <Select
                    fullWidth
                    labelId="select-language-label"
                    id="selectedLanguage"
                    value={selectedLanguage}
                    label="Selected Language"
                    onChange={handleLanguageChange}
                >
                    {translationLocaleData && translationLocaleData.languages && translationLocaleData.languages.map((locale) => (
                        <MenuItem key={locale.translationLocaleId} value={locale.translationLocaleId}>{locale.displayName}</MenuItem>
                    ))}
                </Select>
                <Box sx={{ flexGrow: 1 }} />                
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' },
                    }}
                />
                
                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />                
            </GridToolbarContainer>
        );
    }

    const updateLabel = async (form) => {
        var request = {
            labelId: form.id ?? null,
            translationlocaleId: selectedLanguage,
            labelValue: form.translation
        };
        return await Storefront.updateLabel(request).then((res) => {
            if (res) {
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error("An error occurred while attempting to update the label. Please try again later.");
                }
                getTranslationLabels(selectedLanguage);
            }
        });
    }

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
        getTranslationLabels(event.target.value);
    }

    const getTranslationLocales = () => {
        Storefront.getStorefrontTranslationLocales(storeData.storefrontNumber).then(loadTranslations);
    }
    const getTranslationLabels = (translationLocaleId) => {
        Storefront.getStorefrontTranslations(storeData.storefrontNumber,translationLocaleId).then(loaded);       
    }

    const handleClickOpenUpdate = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);
        setOpenUpdate(true);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    };

    const loadTranslations = (data) => {
        if (data) {
            setTranslationLocaleData(data)
            setSelectedLanguage(data.languages[0].translationLocaleId);
            getTranslationLabels(data.languages[0].translationLocaleId);
        }
        else {
            setTranslationLocaleData([])
        }
    }

    const loaded = (data) => {
        if (data) {
            mapRows(data);
        }
    }

    const mapRows = (data) => {
        var rows = [];
        data.translations.forEach((translation) => {

            rows.push({ id: translation.labelId, components: translation.componentName, labelName: translation.labelName, labelValue: translation.labelValue });
        });
        setRows(rows);
    }

    const renderUpdateButton = (row) => {
        return (
            <>
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            const formJson = Object.fromEntries(formData.entries());
                            updateLabel(formJson);
                            handleCloseUpdate();
                        },
                    }}
                >
                    <DialogTitle>Update Label</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Below are the values that may be updated.
                        </DialogContentText>
                        <TextField
                            defaultValue={row.translation}
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="translation"
                            name="translation"
                            label="Label Value"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUpdate}>Cancel</Button>
                        <Button type="submit">Update</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const columns=[
        
        { field: 'labelName', headerName: 'Label Reference', width: 200 },
        { field: 'components', headerName: 'Components impacted', width: 200 },
        { field: 'labelValue', headerName: 'Label Value', width: 1000 },        {
            field: "updateButton",
            headerName: "Update",
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            Update
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true
        }
    ];

    return (
        <>            
            <Paper sx={{ height: 700, width: '100%' }}>                
                <DataGrid
                    slots={{ toolbar: customToolbar }}                    
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
                    pageSizeOptions={[50, 100]}
                    sx={{ border: 0 }}
                />
            </Paper> 
            {openUpdate && renderUpdateButton(selectedRow)}
        </>
    );
}

export default LabelAdministration;

