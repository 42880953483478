import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Notifications from '../../services/Notifications';
import { InputLabel, Select, MenuItem } from '@mui/material';

export const WarrantyAllowanceAdministration = () => {
    const [rows, setRows] = useState([]);
    const [isAllowance, setIsAllowance] = useState(true);
    const storeData = useStorefrontSettings();
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const responseMessages = {
        "DuplicateKeyViolation": "This Customer Warranty Allowance already exists. Please enter a unique entry.",
        "GenericFailure": "An error occurred while attempting to update the Customer Warranty Allowance. Please try again later.",
        "DeleteSuccess": "Customer Warranty Allowance successfully deleted.",
        "UpdateSuccess": "Customer Warranty Allowance successfully updated.",
        "AddSuccess": "Customer Warranty Allowance successfully added.",
        "InvalidCustomerNumber": "Invalid Customer Number. Please enter a valid Customer Number."
    };

    useEffect(() => {
        getCustomerWarrantyAllowances();
    }, []);


    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' },
                    }}
                />

                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
                {renderAddButton()}
            </GridToolbarContainer>
        );
    }

    const getCustomerWarrantyAllowances = () => {
        Storefront.getWarrantyAllowances(storeData.storefrontNumber).then(loaded);
    }

    const updateWarrantyAllowances = async (form, type) => {
        var request = {
            storefrontNumber: storeData.storefrontNumber,
            customerNumber: form.id,
            isWarrantyAllowance: form.isAllowance,
            type: type
        };
        return await Storefront.updateWarrantyAllowanceCustomer(request).then((res) => {
            if (res) {
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error("An error occurred while attempting to modify or add the Customer Warranty Allowance. Please try again later.");
                }
                getCustomerWarrantyAllowances();
            }
        });
    }

    const loaded = (data) => {
        if (data) {
            mapRows(data);
        }
    }

    const mapRows = (data) => {
        var rows = [];
        data.warrantyAllowanceCustomers.forEach((customerWarrantyAllowance) => {

            rows.push({
                id: customerWarrantyAllowance.customerNumber,
                isAllowance: customerWarrantyAllowance.isWarrantyAllowance
            });
        });
        setRows(rows);
    }

    const handleHasAllowanceChange = (event) => {
        setIsAllowance(event.target.value);
    }

    const handleClickOpenUpdate = (e, row) => {
        e.stopPropagation();
        setIsAllowance(row.isAllowance);
        setSelectedRow(row);
        setOpenUpdate(true);
    };    

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    };

    const handleClickOpenDelete = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleClickOpenAdd = (e) => {
        e.stopPropagation();
        setOpenAdd(true);
    };


    const handleCloseAdd = () => {
        setOpenAdd(false);
        setIsAllowance(true);
    };

    const renderDeleteButton = (row) => {
        return (
            <>
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            const formJson = Object.fromEntries(formData.entries());
                            updateWarrantyAllowances(formJson, "delete");
                            handleCloseDelete();
                        },
                    }}
                >
                    <DialogTitle>Are You Sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this Customer Warranty Allowance?  You can also simply change the rule to false if you want to disable it.
                        </DialogContentText>
                        <DialogContentText ><b>{row.id}</b></DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete}>Cancel</Button>
                        <Button type="submit">Delete</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderUpdateButton = (row) => {
        return (
            <>
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            const formJson = Object.fromEntries(formData.entries());
                            updateWarrantyAllowances(formJson, "update");
                            handleCloseUpdate();
                        },
                    }}
                >
                    <DialogTitle>Update Customer Warranty Allowance</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Below are the values that may be updated.
                        </DialogContentText>
                        <InputLabel id="isAllowance-label">Has Warranty Allowance</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="isAllowance-label"
                            id="isAllowance"
                            name="isAllowance"
                            value={isAllowance}
                            label="Has Warranty Allowance"
                            onChange={(x) => handleHasAllowanceChange(x)}
                        >
                            <MenuItem value={true}>true</MenuItem>
                            <MenuItem value={false}>false</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUpdate}>Cancel</Button>
                        <Button type="submit">Update</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const renderAddButton = () => {
        return (
            <>
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={handleClickOpenAdd}
                    >
                        Add
                    </Button>
                </strong>
                <Dialog
                    open={openAdd}
                    onClose={handleCloseAdd}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            updateWarrantyAllowances(formJson, "add");
                            handleCloseAdd();
                        },
                    }}
                >
                    <DialogTitle>Add Warranty Allowance</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Fill the required fields below.
                        </DialogContentText>
                        <TextField
                            inputProps={{ maxLength: 8 }}
                            required
                            margin="dense"
                            id="id"
                            name="id"
                            label="Customer Number"
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <InputLabel id="isAllowance-label">Has Warranty Allowance</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="isAllowance-label"
                            id="isAllowance"
                            name="isAllowance"
                            value={isAllowance}
                            label="Has Warranty Allowance"
                            onChange={(x) => handleHasAllowanceChange(x)}
                        >
                            <MenuItem value={true}>true</MenuItem>
                            <MenuItem value={false}>false</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAdd}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const columns = [
        { field: 'id', headerName: 'Customer Number', width: 100 },
        { field: 'isAllowance', headerName: 'Has Warranty Allowance', width: 200 },
        {
            field: 'updateButton',
            headerName: 'Update',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            Update
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true

        },
        {
            field: 'deleteButton',
            headerName: 'Delete',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenDelete(e, params.row)}
                        >
                            Delete
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true
        }
    ];



    return (
        <>
            <Paper sx={{ height: 700, width: '100%' }}>
                <DataGrid
                    slots={{ toolbar: customToolbar }}
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
                    pageSizeOptions={[50, 100]}
                    sx={{ border: 0 }}
                />
            </Paper>
            {openUpdate && renderUpdateButton(selectedRow)}
            {openDelete && renderDeleteButton(selectedRow)}
            {openAdd && renderAddButton()}
        </>
    );
}

export default WarrantyAllowanceAdministration;

