import React from 'react';
import { Switch, Route } from "react-router-dom";
import LabelAdministration from './LabelAdministration';
import AdminIndex from './AdminIndex';
import FreightHandlingCodeAdministration from './FreightHandlingCodeAdministration';
import CarrierDetailAdministration from './CarrierDetailAdministration';
import NewsAdministration from './NewsAdministration';
import WarrantyAllowanceAdministration from './WarrantyAllowanceAdministration';

export default function StorefrontAdministration(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/storefront-administration/label-administration"
                ]}
                render={(props) => (
                    <LabelAdministration {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/storefront-administration/freight-handling-code-administration"
                ]}
                render={(props) => (
                    <FreightHandlingCodeAdministration {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/storefront-administration/carrier-detail-administration"
                ]}
                render={(props) => (
                    <CarrierDetailAdministration {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/storefront-administration/news-administration"
                ]}
                render={(props) => (
                    <NewsAdministration {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/storefront-administration/warranty-allowance-administration"
                ]}
                render={(props) => (
                    <WarrantyAllowanceAdministration {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/storefront-administration/index",
                    "/storefront-administration",
                ]}
                render={(props) => (
                    <AdminIndex {...parentProps} {...props} />
                )}
            />
            
        </Switch>
    );
}