import React, { useState, useEffect } from 'react';
import NewsIcon from "../icons/NewsIcon";
import Authorize from '../Authorize';
import * as Roles from '../../resources/UserRole';
import DateHelper from "../../helpers/DateHelper";
import SubmitModal from '../shared/SubmitModal';
import { Container, Row, Col } from 'react-bootstrap';

export const DashboardNews = (props) => {    

    const newsData = props.newsData;
    const dateFormat = props.storeSettings?.settingsList.DateFormat;
    const [isReadMoreModalOpen, setIsReadMoreModalOpen] = useState(false);  
    const [newsContent, setNewsContent] = useState('');
    const [newsTitle, setNewsTitle] = useState('');
    const [newsPostDate, setNewsPostDate] = useState('');
    
    
    const openReadMoreModal = (news) => {
        setNewsTitle(news.title);
        setNewsContent(news.content);
        setNewsPostDate(news.postDate);
        setIsReadMoreModalOpen(!isReadMoreModalOpen);
    }
   
    return (
        <>
          
            <div className="quote-panel" style={{ 'backgroundImage': 'linear-gradient(#b7d5ed, white)' }}>
                <div className="title-flex-container" style={{ paddingBottom: '3rem' }}>
                    <div>     
                        <div><NewsIcon /></div>
                        <div className="title-label">{props.dashboardLabels.NewsHeading}</div>
                        </div>
                    </div>

                    <div className="data-flex-container" >
                    {(newsData && newsData.headerResponse && newsData.headerResponse.length > 0) ?
                            <ul>
                            {newsData.headerResponse.map((news, key) => {
                                return <>
                                    <div style={{ paddingBottom: '2rem' }}>
                                    <Row className="heading3">
                                        <Col xl={12}>{news.title}
                                        <div className="button-wrapper" style={{ height: '2rem' }}>
                                            <button className="remove">
                                                <a className="link" style={{ fontSize: '1.6rem' }} onClick={() => openReadMoreModal(news)}
                                                >{props.dashboardLabels.ReadMore}</a>
                                            </button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div>{DateHelper.format(news.postDate, dateFormat)}</div>
                                    </div>
                                </>
                                })
                        }
                                        </ul>
                        : <div>{props.dashboardLabels.NoNews}</div>
                        }
                    </div>
                <SubmitModal
                    isOpen={isReadMoreModalOpen}
                    onClose={() => { setIsReadMoreModalOpen(!isReadMoreModalOpen); }}
                    showFooter={false}
                    HeaderText={props.dashboardLabels.NewsHeading}
                    size={"lg"}
                    isScrollable={true}
                >
                    <Container>                       

                        <Row className="heading1" style={{ paddingBottom: '1rem' }}>
                            <Col xl={12}>
                                <div className="text-left">
                                    <div>{newsTitle}</div> 
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ paddingBottom: '0rem' }}>
                            <Col xl={12} style={{ textAlign: 'right' }}>
                                <div className="heading4" style={{ color: '#848484', fontWeight: '500', lineHeight: '21px' }}>{DateHelper.format(newsPostDate, dateFormat)}</div>
                            </Col>
                        </Row>
                        <Row style={{ paddingBottom: '2rem' }}>
                            <div style={{ textAlign: 'justify', fontSize: '1.8rem', lineHeight: '24px' }}>
                                {newsContent}
                            </div>
                        </Row>

                        <Row> &nbsp;</Row>
                       
                    </Container>
                </SubmitModal>
            </div>
        </>

    );
}


export default DashboardNews;