import React, { useState,useEffect} from 'react';
import { connect } from 'react-redux';
import ActiveOrders from './dashboard/ActiveOrders';
import ActiveQuotes from './dashboard/ActiveQuotes';
import DashboardOrderHistory from './dashboard/DashboardOrderHistory'
import ActiveInvoices from './dashboard/ActiveInvoices';
import OrderService from '../services/OrderService';
import { withSelectedCustomer } from '../hooks/withSelectedCustomer';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';
import { useDashboardConfig, withDashboardConfig } from '../hooks/DashboardConfigContext';
import PropTypes from 'prop-types';
import MessageStandard from './MessageStandard';
import SearchIcon from './icons/Search';
import Loading from '../components/Loading';
import * as Components from '../resources/Components';
import DashboardQuoteHistory from './dashboard/DashboardQuoteHistory'
import DashboardYourSelections from './dashboard/DashboardYourSelections';
import PricingHelper from '../helpers/PricingHelper';
import DashboardNews from './dashboard/DashboardNews'

export const Home = (props) => {
    const customerData = props.selectedCustomer;
    const [country, setCountry] = React.useState("");
    const storeData = props.StorefrontConfig;
    const [storefrontNumber, setStorefrontNumber] = React.useState(('00000' + storeData.storefrontNumber).slice(-5));
    const dashboardLabels = useDashboardConfig().labels;
    const showNewsSection = useDashboardConfig().settings?.ShowNewsSection;
    const [invoiceData, setInvoiceData] = useState({ totalOpenInvoices: 0, headerResponse: [] });
    const [orderData, setOrderData] = useState({ totalActiveOrders: 0, headerOrdersResponse: [], salesOrdersResponse: [] });
    const [quoteData, setQuoteData] = useState({ totalOpenQuotes: 0, headerResponse: [] });
    const [monthlyTotals, setMonthlyTotals] = React.useState({});
    const [currencySymbol, setCurrencySymbol] = React.useState();
    const [currency, setCurrency] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [selectionData, setSelectionData] = useState()
    const [newsData, setNewsData] = useState({ headerResponse: [] });
    useEffect(async() => {
        if (props && props.selectedCustomer) {
            await getInvoiceData();
            await getSelectionData();
            await getOrderData();
            await getQuoteData();   
            await getNewsData();
        }
       
    }, [props.selectedCustomer, props.StorefrontConfig.locale]);

    const getOrderData = () => {
        setLoading(false)
        return OrderService.getDashboardOrders(customerData.id, storeData.storefront.id, customerData.companyCode).then((data) => {
            setCountry(customerData.address.country);
            if (data) {
                setOrderData(data);
                updateCurrencyInformation(data);    
                calculate12MonthTotals(data);
            }
            else {
                setOrderData({ totalActiveOrders: 0, headerOrdersResponse: [], salesOrdersResponse: [] })
            }
      
            setLoading(true)
        });
    }

    const getQuoteData = () => {
        setLoading(false)
        return OrderService.getDashboardQuotes(customerData.id, storeData.storefront.id, customerData.companyCode, 10,props.StorefrontConfig.settingsList.QuoteDayRange).then((data) => {
            if (data) {
                setQuoteData(data);
            }
            else {
                setQuoteData({ totalOpenQuotes: 0, headerResponse: [] })
               
            }
            setLoading(true)
        });
    }

    const getInvoiceData = () => {
        return OrderService.getDashboardInvoices(customerData.id, storeData.storefront.id, customerData.companyCode, 10).then((data) => {
            if (data) {
                setInvoiceData(data);
            }
            else {
                setInvoiceData({ totalOpenQuotes: 0, headerResponse: [] })
            }
        });
    }

    const getSelectionData = () => {
        return OrderService.getDashboardFESelections(props.userEmail).then((data) => {
            if (data) {
                setSelectionData(data);
            }
            else {
                setSelectionData("");
            }
        });
    }

    const getCurrencySymbol = (locale, currency) => (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();
    const calculate12MonthTotals = (data) => {
        let monthData = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        let dateOrderedMonthData = [];
        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth();
        let oneYearData = data.salesOrdersResponse.filter(x => new Date(x.orderDate).getFullYear() === currentYear || new Date(x.orderDate).getFullYear() === currentYear - 1 && new Date(x.orderDate).getMonth() > new Date().getMonth());

        if (oneYearData && oneYearData.length > 0) {
            oneYearData.forEach((order) => {
                let monthNumber = new Date(order.orderDate).getMonth();
                let monthName = monthData[monthNumber];
                let isCurrentYear = new Date(order.orderDate).getFullYear() === currentYear;
                if (dateOrderedMonthData.some(x => x.month === monthName)) {
                    let index = dateOrderedMonthData.findIndex(x => x.month === monthName);
                    dateOrderedMonthData[index].total += order.total;
                }
                else {
                    dateOrderedMonthData.push({ month: monthName, total:order.total, currentYear: isCurrentYear });
                }                         
            });        
            for (let i = 0; i <= currentMonth; i++) {
                let monthName = monthData[i];
                if (!dateOrderedMonthData.some(x => x.month === monthName)) {
                    dateOrderedMonthData.push({ month: monthName, total: 0, currentYear: true });
                }
            }
            for (let i = currentMonth-1; i < 12; i++) {
                let monthName = monthData[i];
                if (!dateOrderedMonthData.some(x => x.month === monthName)) {
                    dateOrderedMonthData.push({ month: monthName, total: 0, currentYear: false });
                }
            }
            dateOrderedMonthData.sort((a, b) => {
                if (a.currentYear === b.currentYear) {
                    return monthData.indexOf(a.month) - monthData.indexOf(b.month);
                }
                else {
                    return a.currentYear ? 1 : -1;
                }
            });
            setMonthlyTotals(dateOrderedMonthData);  
        }
        
    }
    const updateCurrencyInformation = (data) => {
        if (data && data.salesOrdersResponse && data.salesOrdersResponse.length > 0) {
            let updatedSymbol = getCurrencySymbol(PricingHelper.fetchLocale(country), data.salesOrdersResponse[0].priceCurrency.toLowerCase());
            setCurrency(data.salesOrdersResponse[0].priceCurrency.toLowerCase());
            setCurrencySymbol(updatedSymbol);
        }
    }
    const getNewsData = () => {
        setLoading(false)
        return OrderService.getDashboardNews(storeData.storefront.id, 5).then((data) => {
            if (data) {
                setNewsData(data);
            }
            else {
                setNewsData({ headerResponse: [] })

            }
            setLoading(true)
        });
    }
        return (
            <>

                {!loading ? <Loading type="brand" /> : storeData.settingsList.UseDashBoard == 1 ?
                    <div className="fele-home">
                        <div className={props.isFueling ? "active-panel-fueling" : "active-panel"}>
                            <div className="header-text">{dashboardLabels.DashboardHeaderText}</div>
                            <div className="flex-container">
                                <ActiveOrders availableStatuses={storeData.storefrontOrderQuoteStatuses} locale={storeData.locale} orderData={orderData} isFueling={props.isFueling} dashboardLabels={dashboardLabels} storeSettings={storeData} />
                                <ActiveQuotes availableStatuses={storeData.storefrontOrderQuoteStatuses} quoteData={quoteData} dashboardLabels={dashboardLabels} QuoteDayRange={storeData.settingsList.QuoteDayRange} />
                                <ActiveInvoices invoiceData={invoiceData} dashboardLabels={dashboardLabels} storeSettings={storeData} />
                            </div>
                        </div>
                        <div className={props.isFueling ? "ffs-dashy-boi" : "dashy-boi"}></div>
                        <div className="history-panel-container">
                            
                            {
                                showNewsSection === true &&
                                <DashboardNews newsData={newsData} storeSettings={storeData} dashboardLabels={dashboardLabels} />
                            }
                            <DashboardOrderHistory tdMoneyAlign={storeData.settingsList.TdMoneyAlign} dateFormat={storeData.settingsList.DateFormat} isFueling={props.isFueling} orderData={orderData} country={country} currency={currency} currencySymbol={currencySymbol} monthlyTotals={monthlyTotals} storefrontNumber={storefrontNumber} dashboardLabels={dashboardLabels} storeSettings={storeData} />
                            <DashboardQuoteHistory tdMoneyAlign={storeData.settingsList.TdMoneyAlign} dateFormat={storeData.settingsList.DateFormat} isFueling={props.isFueling} quoteData={quoteData} currency={currency} country={country} storefrontNumber={storefrontNumber} dashboardLabels={dashboardLabels} QuoteDayRange={storeData.settingsList.QuoteDayRange} />
                            <DashboardYourSelections selections={selectionData} storeSettings={storeData} />                                                
                        </div>
                    </div> : <div className="fele-home" style={{ textAlign: "center" }}>
                        <MessageStandard
                            icon={<SearchIcon />}
                            line1={storeData.labels.SearchPriceAndAvailabilityMessage}
                        />
                        {storeData.labels.SearchPriceAndAvailabilityDirections}

                    </div>

                }
            </>
        );
    };
//<DashboardYourSelections orderData={orderData} storeSettings={storeData} />  hiding until approved.
Home.propTypes = {
    selectedCustomer: PropTypes.object
};
function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
    };
}

export default connect(mapStateToProps, null)(withStorefrontConfig(withSelectedCustomer(withDashboardConfig(Home))));