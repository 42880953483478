import React from "react";
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import CloseIcon from '../icons/Close';

export default function SubmitModal({
    isOpen,
    children,
    onClose,
    onSubmit,
    submitText,
    closeText,
    showFooter = true,
    size = "md",
    HeaderText,
    zIndex,
    isScrollable = false
}) {

    const ModalHeaderStyle = {
        backgroundColor: '#f8f8f8',
        border: 'none',
        paddingRight: '1rem',
        paddingTop: '1rem',
    }
    const ModalHeaderTextStyle = {
        paddingBotom: '1rem',
         height: '29px'
    }
    const ModalBodyStyle = {
        paddingTop: '1.2rem',
        paddingLeft: '3rem',
        paddingRight: '1rem'
    }

    const ModalFooterStyle = {
        padding: '0.75rem 3rem',
    }
  
    return (
        <Modal
            show={isOpen}
            backdrop="static"
            keyboard={false}
            centered
            scrollable={isScrollable}
            animation={true}
            onHide={onClose}
            size={size}
            style={{ zIndex: zIndex || 1500 }}
            backdropClassName={zIndex ? "backdrop-override" : ""}
        >
            <Modal.Header style={ModalHeaderStyle}>
                <Container>
                    <Row style={ModalHeaderTextStyle}>
                            <Col className="heading2" style={{ paddingBottom: '1rem' }}>
                                {HeaderText}
                            </Col>

                        <Col className="button-wrapper" style={{ textAlign: 'right' }}>
                            <span style={{ cursor: 'pointer' }} onClick={onClose}>
                                <CloseIcon />
                            </span>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body style={ModalBodyStyle}>
                {children}
            </Modal.Body>
            {showFooter &&
                <Modal.Footer style={ModalFooterStyle}>
                    <Row>
                        <Col xs={4} md={4} className="button-wrapper">
                        <Button variant="secondary" className="button button-secondary" onClick={onClose}>{closeText}</Button>
                        </Col>&nbsp;
                        <Col xs={4} md={4} className="button-wrapper text-nowrap">
                            <Button variant="primary" className="button text-nowrap" style={{paddingLeft:"5px", paddingRight:"5px", width:"fit-content"}} onClick={onSubmit}>{submitText}</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            }
        </Modal>
    );
}