import React from 'react';
import PropTypes from 'prop-types';
import CartIcon from '../icons/CartIcon';
import OrderSurvey from "../survey/OrderSurvey";
import { withSelectedCustomer } from '../../hooks/withSelectedCustomer';
import { withCartConfig } from '../../hooks/CartConfigContext';

export class OrderPlaced extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const localized = this.props.CartConfig.labels;
        const orderMsg = (!this.props.IsQuote) ? localized.ThankYouForYourOrder : localized.ThankYouForYourQuote;
        const emailMsg = (!this.props.IsQuote) ? localized.ReceiveOrderEmail : localized.ReceiveQuoteEmail;
        const documentLabel = (!this.props.IsQuote) ? localized.OrderNumber : localized.QuoteNumber;
        const showDocNumber = this.props.DocumentNumber && this.props.DocumentNumber.trim().length > 0;
        const settings = this.props.CartConfig.settings;
        const storefrontSettings = this.props.CartConfig.storefrontsettings;
        return (
            <div className="fele-order-complete fele-message-standard">
                <center>   
                <div>
                        <div className="heading1" style={{ "margin-top": "15px", "margin- bottom": "15px" }} data-cy="cart-order-placed">{orderMsg}</div>
                        <p style={{ "margin-top": "15px" }}>{emailMsg}</p>
                </div>

                {
                    settings.ShowSurvey ? (
                        <OrderSurvey localized={localized} surveyCookieExpiryDays={Number(storefrontSettings.SurveyCookieExpiryDays)} />
                    ) : null
                }

                {showDocNumber &&
                    <div style={{ marginTop: '40px' }}>
                        <div className="card text-center border-1 rounded-3 border-dark" style={{ width: '26rem', height: '14rem' }}>
                            <div className="card-body">
                                <div style={{ marginTop: '25px' }}>
                                    <h1 className="card-title"><strong>{documentLabel}</strong></h1>
                                    <p className="card-text">{this.props.DocumentNumber}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </center>
            </div>
        );
    }
}

OrderPlaced.propTypes = {
    DocumentNumber: PropTypes.string,
    IsQuote: PropTypes.bool,
    ShowError: PropTypes.bool
};

export default withSelectedCustomer(withCartConfig(OrderPlaced));