import React, { useEffect, useState,useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Calendar from '../form-controls/Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import BasicModal from '../shared/ModalBasic';
import { useCartConfig } from '../../hooks/CartConfigContext';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import DateHelper from '../../helpers/DateHelper';

const LineRequestDate = props => {
    const cartOptions = useCartConfig();
    const storefrontConfig = useStorefrontSettings();
    const localized = cartOptions.labels;
    const minDate = GetMinDate();
    const buttonStyle = {
        border: 'none',
        backgroundColor: 'rgba(0,0,0,0)'
    }
    useEffect(() => {
        GetMinDate();
    }, [props.headerRequestDate])
    function GetMinDate() {
        var d = new Date();
        var headerRequestDate = new Date(props.headerRequestDate);
        headerRequestDate.setHours(0, 0, 0, 0);
        d.setHours(0, 0, 0, 0);

        var daysForMType = storefrontConfig.settingsList.NoOfDaysForMType === undefined ? 0 : Number(storefrontConfig.settingsList.NoOfDaysForMType);
        var daysForPType = storefrontConfig.settingsList.NoOfDaysForPType === undefined ? 0 : Number(storefrontConfig.settingsList.NoOfDaysForPType);

        var chosenDate = (headerRequestDate > d) ? headerRequestDate : d;
        if (props.item.product.stockingType == 'M' && props.cartSettings.settings.UseStockingTypeForRequestDeliveryDate == true) {
            chosenDate.setDate(chosenDate.getDate() + daysForMType);
        }
        else if (props.item.product.stockingType == 'P' && props.cartSettings.settings.UseStockingTypeForRequestDeliveryDate == true) {
            chosenDate.setDate(chosenDate.getDate() + daysForPType);
        }

        return DateHelper.subtract(chosenDate, { days: (cartOptions.transitDays * -1) });
    }

    const handleRequestedDeliveryDateChange = (date, lineChange=true) => {
        if (date) {
            let newItem = { ...props.item };
                newItem.requestedDeliveryDate = DateHelper.getUTCDate(date);
                props.updateCartItem(newItem, !lineChange);
            
        }
    }
    const [showRequestDateMessage, setShowRequestDateMessage] = useState(false);
    if (cartOptions.childComponents.LineRequestDate.shown !== true) {
        return null
    }

    
    return (
        <div className="cart-requested-delivery-date d-block">
            <div className="flex">
                <div className="flex-0 label d-block">{localized.RequestedDeliveryDateLabel}</div>
                {(cartOptions.childComponents.LineRequestDateLabel === false) &&
                    <div className="flex-1 ml-4">
                        <button style={buttonStyle} onClick={() => setShowRequestDateMessage(!showRequestDateMessage)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </button>
                    </div>
                }
            </div>
            <BasicModal
                isOpen={showRequestDateMessage}
                onClose={() => setShowRequestDateMessage(false)}>
                <div>
                    Si necesita una fecha diferente al de la orden general para alguno de los artículos en el carrito por favor seleccione la fecha nueva debajo de cada item, de lo contrario deje en blanco esta casilla
                </div>
            </BasicModal>
            <Calendar date={props.item.requestedDeliveryDate} minDate={minDate} callBackFunction={handleRequestedDeliveryDateChange}
                showIcon={true} dateFormat={props.dateFormat} />
        </div>
    )
}

LineRequestDate.propTypes = {
    updateCartItem: PropTypes.func,
    item: PropTypes.object,
    components: PropTypes.object,
    dateFormat: PropTypes.string,
    repriceCart: PropTypes.func,
    calculating: PropTypes.bool,
    headerRequestDate: PropTypes.string
};

export default LineRequestDate;