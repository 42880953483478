import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Notifications from '../../services/Notifications';


export const FreightHandlingCodeAdministration = (props) => {
    const [freightHandlingCodeData, setFreightHandlingCodeData] = useState([]);
    const [rows, setRows] = useState([]);
    const storeData = useStorefrontSettings();
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const responseMessages = {
        "DeleteForeignKeyConstraintViolation": "This Freight Handling Code is currently being referenced by another data set, likely a Carrier. Please remove the reference data before attempting to delete this Freight Handling Code.",
        "UpdateForeignKeyConstraintViolation": "This Freight Handling Code is currently being referenced by another data set, likely a Carrier. Please remove the reference data before attempting to update this Freight Handling Code.",
        "DuplicateKeyViolation": "This Freight Handling Code already exists for this storefront. Please enter a unique Freight Handling Code.",
        "GenericFailure": "An error occurred while attempting to update the Freight Handling Code. Please try again later.",
        "DeleteSuccess": "Freight Handling Code successfully deleted.",
        "UpdateSuccess": "Freight Handling Code successfully updated.",
        "AddSuccess": "Freight Handling Code successfully added."
    };

    useEffect(() => {
        getFreightHandlingCodes();
    }, []);

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' },
                    }}
                />

                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
                {renderAddButton()}
            </GridToolbarContainer>
        );
    }

    const getFreightHandlingCodes = () => {
        Storefront.getFreightHandlingCodes(storeData.storefrontNumber).then(loaded);
    }

    const updateFreightHandlingCode = async (form, type) => {
        var request = {
            id: form.Id ?? null,
            code: form.code ?? null,
            storefrontNumber: storeData.storefrontNumber,
            description: form.description ?? null,
            operationType: type
        };
        return await Storefront.updateFreightHandlingCode(request).then((res) => {
            if (res) {      
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error("An error occurred while attempting to modify or add the Freight Handling Code. Please try again later.");
                }
                getFreightHandlingCodes();
            }   
        });
    }

    const loaded = (data) => {
        if (data) {
            setFreightHandlingCodeData(data)
            mapRows(data);
        }
        else {
            setFreightHandlingCodeData({})
        }
    }

    const mapRows = (data) => {
        var rows = [];
        data.freightHandlingCodes.forEach((freightHandlingCode, index) => {

            rows.push({ id: index, codeId: freightHandlingCode.id, code: freightHandlingCode.code, description: freightHandlingCode.description, });
        });
        setRows(rows);
    }

    const handleClickOpenUpdate = (e,row) => {    
        e.stopPropagation();
        setSelectedRow(row);
        setOpenUpdate(true);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    };
    const handleClickOpenDelete = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);    
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleClickOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const renderDeleteButton = (row) => {
        return (
            <>                
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("Id", row.codeId);
                            const formJson = Object.fromEntries(formData.entries());
                            updateFreightHandlingCode(formJson, "delete");
                            handleCloseDelete();
                        },
                    }}
                >
                    <DialogTitle>Are You Sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this Freight Handling Code?  You may be blocked from doing so if it still has any attached Carriers.
                        </DialogContentText>
                        <DialogContentText ><b>{row.code}</b></DialogContentText>                       
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete}>Cancel</Button>
                        <Button type="submit">Delete</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderUpdateButton = (row) => {
        return (
            <>            
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("Id", row.codeId);
                            const formJson = Object.fromEntries(formData.entries());
                            updateFreightHandlingCode(formJson,"update");
                            handleCloseUpdate();
                        },
                    }}
                >
                    <DialogTitle>Update Freight Handling Code</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Below are the values that may be updated.
                        </DialogContentText>
                        <TextField
                            defaultValue={row.code}
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="code"
                            name="code"
                            label="Code"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            defaultValue={row.description}
                            inputProps={{ maxLength: 500 }}
                            required
                            margin="dense"
                            id="description"
                            name="description"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUpdate}>Cancel</Button>
                        <Button type="submit">Update</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderAddButton = (params) => {
        return (
            <>
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={handleClickOpenAdd}
                    >
                        Add
                    </Button>
                </strong>
                <Dialog
                    open={openAdd}
                    onClose={handleCloseAdd}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            //formData.append("Id", params.row.codeId);
                            const formJson = Object.fromEntries(formData.entries());
                            updateFreightHandlingCode(formJson, "add");
                            handleCloseAdd();
                        },
                    }}
                >
                    <DialogTitle>Add Freight Handling Code</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Fill the required fields below.
                        </DialogContentText>
                        <TextField
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="code"
                            name="code"
                            label="Code"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            inputProps={{ maxLength: 500 }}
                            required
                            margin="dense"
                            id="description"
                            name="description"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAdd}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const columns = [
        { field: 'code', headerName: 'Code', width: 200 },
        { field: 'description', headerName: 'Description', width: 1000 },
        {
            field: 'updateButton',
            headerName: 'Update',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            Update
                        </Button>
                    </strong>
                )
            },            
            disableClickEventBubbling: true
            
        },
        {
            field: 'deleteButton',
            headerName: 'Delete',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenDelete(e, params.row)}
                        >
                            Delete
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true
        }
    ];



    return (
        <>
            <Paper sx={{ height: 700, width: '100%' }}>
                <DataGrid
                    slots={{ toolbar: customToolbar }}
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
                    pageSizeOptions={[50, 100]}
                    sx={{ border: 0 }}
                />
            </Paper>
            {openUpdate && renderUpdateButton(selectedRow)}
            {openDelete && renderDeleteButton(selectedRow)}
        </>
    );
}

export default FreightHandlingCodeAdministration;

