import React from 'react';
import PDFIcon from '../../icons/OrderAckIcon';
import Api from '../../../services/Api';
import Notifications from "../../../services/Notifications";

export const ShipmentInfo = (props) => {
    const handleError = (res) => {
        if (!res) Notifications.error(localized.PackinglistNotFoundMessage);
    };

    const getPDF = (shipment) => {
        Api.downloadFileName(
                `api/orders/GetPDFPackingList/${shipment.soldToId}/${shipment.shipmentNumber}/${shipment.orderType}`,
            `${shipment.soldToId}-${shipment.shipmentNumber}${shipment.orderType}`
        ).then(handleError);
    };   

        const { shippingAddress, shipment, localized } = props;
        return (
            shipment &&
            <div className="shipment-info">
                <div className="control-group shipto" style={{ width: "40%" }}>
                    <div className="label">{localized.ShipToLabel}</div>
                    <address className="fele-content">
                        <div className="name">{shippingAddress.name}</div>
                        <div className="line1">{shippingAddress.lineOne}</div>
                            <div className="line2">{shippingAddress.lineTwo}</div>
                            <div className="cityStateZip">{shippingAddress.city}{shippingAddress.city && shippingAddress.state ? ', ':""}{shippingAddress.state} {shippingAddress.postalCode}</div>
                        <div className="country">{shippingAddress.country}</div>
                    </address>
                </div>
                    <div className="control-group carrier" style={{ width: "15%" }}>
                    <div className="label">{localized.CarrierLabel}</div>
                    <div className="fele-content">
                        {
                            shipment.carrierName && shipment.trackingUrl &&
                            <a className="link"
                                href={shipment.trackingUrl} target="_blank" rel="noopener noreferrer">
                                {shipment.carrierName}
                            </a>
                        }
                        {
                            !shipment.carrierName &&
                            localized.CarrierNotFoundMessage
                        }
                        {
                            shipment.carrierName && !shipment.trackingUrl &&
                            shipment.carrierName
                        }

                    </div>
                </div>
                    <div className="control-group tracking-number" style={{ width: "15%" }}>
                    <div className="label">{localized.TrackingNumber}</div>
                    <div className="fele-content">
                        {
                            !shipment.trackingNumber &&
                            localized.CarrierNotFoundMessage
                        }
                        {
                            !shipment.trackingUrl && shipment.trackingNumber &&
                            shipment.trackingNumber
                        }
                        {
                            shipment.trackingUrl && shipment.trackingNumber &&
                            <a className="link"
                                href={shipment.trackingUrl} target="_blank" rel="noopener noreferrer">
                                {shipment.trackingNumber.trim()}
                            </a>
                        }
                    </div>
                    </div>

                    <div className="control-group shipment-number" style={{ width: "15%" }}>
                        <div className="label">{localized.ShipmentNumber}</div>
                        <div className="fele-content">
                            {
                                !shipment.shipmentNumber &&
                                localized.ShipmentNumberNotFoundMessage
                            }
                            {
                                !!shipment.shipmentNumber &&
                                shipment.shipmentNumber
                            }
                            
                            
                        </div>
                    </div>
                    <div className="control-group packing-list" style={{ width: "15%" }}>
                        <div className="label">{localized.PackingList}</div>
                        <div className="fele-content">
                            {
                                !shipment.shipmentNumber &&
                                localized.PackinglistNotFoundMessage
                            }
                            {
                                !!shipment.shipmentNumber &&
                                <div style={{ cursor: 'pointer'}} onClick={() => { getPDF(shipment) }}>
                            <PDFIcon />
                        </div>                                
                            }
                            
                        </div>
                    </div>

            </div>
        );
}
export default ShipmentInfo;
