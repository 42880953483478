import { useCallback } from "react";

const useSetCookie = () => {
  const setCookie = useCallback((name, value, days, domain = null) => {
    const isLocalhost = window.location.hostname === "localhost";
    const maxAge = days * 24 * 60 * 60;
    const path = "Path=/";
    const sameSite = "SameSite=Strict";

    // Only set Secure for production (non-localhost)
    const secure = isLocalhost ? "" : "Secure";

    // Default domain to the current domain for production
    const currentDomain = isLocalhost
      ? ""
      : `Domain=${domain || window.location.hostname};`;

    document.cookie = `${name}=${value}; max-age=${maxAge}; ${path}; ${sameSite}; ${secure}`;
  }, []);

  return { setCookie };
};

export default useSetCookie;
