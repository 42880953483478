import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Notifications from '../../services/Notifications';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {InputLabel,Select, FormLabel, MenuItem} from '@mui/material';


export const CarrierDetailAdministration = (props) => {
    const [carrierDetailData, setCarrierDetailData] = useState([]);
    const [freightHandlingCodeData, setFreightHandlingCodeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [freightCode1, setFreightCode1] = useState('');
    const [freightCode2, setFreightCode2] = useState('');
    const [showExtraInformation, setShowExtraInformation] = useState(true);
    const [effectiveDateValue, setEffectiveDateValue] = useState(new Date());
    const [expirationDateValue, setExpirationDateValue] = useState(new Date());
    const [rows, setRows] = useState([]);
    const storeData = useStorefrontSettings();
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const responseMessages = {
        "GenericFailure": "An error occurred while attempting to update the Carrier Details. Please try again later.",
        "DeleteSuccess": "Carrier Details successfully deleted.",
        "UpdateSuccess": "Carrier Details successfully updated.",
        "AddSuccess": "Carrier Details successfully added."
    };
    var addEffectiveDateValue = new Date();
    var addExpirationDateValue = new Date();

    useEffect(() => {
            getCarrierDetails();
    }, []);

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' },
                    }}
                />

                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={(e)=>handleClickOpenAdd(e)}
                    >
                        Add
                    </Button>
                </strong>
            </GridToolbarContainer>
        );
    }

    const getCarrierDetails = () => {
        setLoading(true);
        Storefront.getCarrierDetails(storeData.storefrontNumber).then(loaded);
    }

    const updateCarrierDetails = async (form, type) => {
        setLoading(true);
        var useEffectiveDate = type === "add" ? addEffectiveDateValue : effectiveDateValue;
        var useExpirationDate = type === "add" ? addExpirationDateValue : expirationDateValue;     
        var request = {
            id:form.id ?? null,
            storefrontNumber: storeData.storefrontNumber,   
            carrierId: form.carrierId,                     
            country: form.country,
            state: form.state,
            currencyCode: form.currencyCode,
            thresholdAmount: form.amount,
            freightCode1: form.freightCode1,
            freightCode2: form.freightCode2,
            label1: form.freightCode1Label,
            label2: form.freightCode2Label,
            showInformation: form.showExtraInformation,
            effectiveDate: useEffectiveDate,
            expirationDate: useExpirationDate,
            operationType: type
        };
        addEffectiveDateValue = new Date();
        addExpirationDateValue = new Date();
        return await Storefront.updateCarrierDetails(request).then((res) => {
            if (res) {
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error("An error occurred while attempting to modify or add the Carrier Details. Please try again later.");
                }
                getCarrierDetails();
            }
        });
    }

    const loaded = (data) => {
        if (data && data.carrierDetails && data.freightHandlingCodes) {
            setCarrierDetailData(data.carrierDetails);
            setFreightHandlingCodeData(data.freightHandlingCodes);
            mapRows(data.carrierDetails);
        }
        else {
            setCarrierDetailData({})
        }
        setLoading(false)
    }

    const mapRows = (carrierDetails) => {
        var rows = [];
        carrierDetails.forEach((carrierDetail) => {

            rows.push({
                id: carrierDetail.id,
                carrierId: carrierDetail.carrierId,
                companyCode: carrierDetail.companyCode,
                country: carrierDetail.country,
                state: carrierDetail.state,
                currencyCode: carrierDetail.currencyCode,
                thresholdAmount: carrierDetail.amount,
                belowThresholdCode: carrierDetail.freightCode1,
                atExceedsThresholdcode: carrierDetail.freightCode2,
                correspondingLabel1: carrierDetail.freightCode1Label,
                correspondingLabel2: carrierDetail.freightCode2Label,
                showInfo: carrierDetail.showExtraInformation,
                effectiveDate: carrierDetail.effectiveDate,
                expirationDate: carrierDetail.expirationDate
            });
        });
        setRows(rows);
    }

    const handleClickOpenUpdate = (e, row) => {
        e.stopPropagation();
        setFreightCode1(row.atExceedsThresholdcode);
        setFreightCode2(row.belowThresholdCode);
        setShowExtraInformation(row.showInfo);
        setEffectiveDateValue(row.effectiveDate);
        setExpirationDateValue(row.expirationDate);
        setSelectedRow(row);
        setOpenUpdate(true);
    };

    const handleCloseUpdate = () => {
        setFreightCode1("");
        setFreightCode2("");
        setShowExtraInformation(true);
        setOpenUpdate(false);
    };
    const handleClickOpenDelete = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setEffectiveDateValue(new Date());
        setExpirationDateValue(new Date());
    };

    const handleClickOpenAdd = (e) => {
       e.stopPropagation();
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
        addEffectiveDateValue = new Date();
        addExpirationDateValue = new Date();
        setFreightCode1("");
        setFreightCode2("");
        setShowExtraInformation(true);
    };

    const handleFreightCode1Change = (event) => {
        setFreightCode1(event.target.value);
    }
    const handleFreightCode2Change = (event) => {
        setFreightCode2(event.target.value);
    }

    const handleShowExtraInformationChange = (event) => {
        setShowExtraInformation(event.target.value);
    }

    const handleEffectiveDateChangeUpdate = (e) => {
        setEffectiveDateValue(e);
    }

    const handleExpirationDateChangeUpdate = (e) => {
        setExpirationDateValue(e);
    }

    const handleEffectiveDateChangeAdd = (e) => {
        addEffectiveDateValue = e;
    }

    const handleExpirationDateChangeAdd = (e) => {
        addExpirationDateValue = e;
    }

    const renderDeleteButton = (row) => {
        return (
            <>
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            const formJson = Object.fromEntries(formData.entries());
                            updateCarrierDetails(formJson, "delete");
                            handleCloseDelete();
                        },
                    }}
                >
                    <DialogTitle>Are You Sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this Carrier?
                        </DialogContentText>
                        <DialogContentText ><b>{row.code}</b></DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete}>Cancel</Button>
                        <Button type="submit">Delete</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderUpdateButton = (row) => {
        return (
            <>
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("carrierId", row.carrierId);
                            formData.append("id", row.id);
                            formData.append("country", row.country);
                            formData.append("state", row.state);
                            const formJson = Object.fromEntries(formData.entries());
                            updateCarrierDetails(formJson, "update");
                            handleCloseUpdate();
                        },
                    }}
                >
                    <DialogTitle>Update Carrier Details</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Below are the values that may be updated.
                        </DialogContentText>
                        <TextField
                            defaultValue={row.currencyCode}
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="currencyCode"
                            name="currencyCode"
                            label="Currency Code"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            defaultValue={row.thresholdAmount}
                            inputProps={{ maxLength: 15 }}
                            required
                            margin="dense"
                            id="amount"
                            name="amount"
                            label="Amount"
                            type="decimal"
                            fullWidth
                            variant="standard"
                        />
                        <InputLabel id="freightCode1-label">Freight Code 1</InputLabel>
                        <Select
                            fullWidth
                            labelId="freightCode1-label"
                            id="freightCode1"
                            name="freightCode1"
                            value={freightCode1}
                            label="Freight Code 1"
                            onChange={handleFreightCode1Change}
                        >
                            {freightHandlingCodeData && freightHandlingCodeData.map((freightCode) => (
                                <MenuItem key={freightCode.code} value={freightCode.code}>{freightCode.code}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel id="freightCode2-label">Freight Code 2</InputLabel>
                        <Select
                            fullWidth
                            labelId="freightCode2-label"
                            id="freightCode2"
                            name="freightCode2"
                            value={freightCode2}
                            label="Freight Code 2"
                            onChange={handleFreightCode2Change}
                        >
                            {freightHandlingCodeData && freightHandlingCodeData.map((freightCode) => (
                                <MenuItem key={freightCode.code} value={freightCode.code}>{freightCode.code}</MenuItem>
                            ))}
                        </Select>
                        <TextField
                            defaultValue={row.correspondingLabel1}
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="freightCode1Label"
                            name="freightCode1Label"
                            label="Above Code Label"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            defaultValue={row.correspondingLabel2}
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="freightCode2Label"
                            name="freightCode2Label"
                            label="Below Code Label"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <InputLabel id="showExtraInformation-label">Show Extra Information</InputLabel>
                        <Select
                            fullWidth
                            labelId="showExtraInformation-label"
                            id="showExtraInformation"
                            name="showExtraInformation"
                            value={showExtraInformation}
                            label="Show Extra Information"
                            onChange={handleShowExtraInformationChange}
                        >
                            <MenuItem value={true}>true</MenuItem>
                            <MenuItem value={false}>false</MenuItem>
                        </Select>
                        <FormLabel required sx={{ fontSize: 10 }}>Effective Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                defaultValue={new Date(row.effectiveDate)}
                                value={new Date(effectiveDateValue)}
                                onChange={() => true}
                                onAccept={(x) => handleEffectiveDateChangeUpdate(x, row)}
                            />
                        </LocalizationProvider>
                        <FormLabel required sx={{ fontSize: 10 }}>Expiration Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                defaultValue={new Date(row.expirationDate)}
                                value={new Date(expirationDateValue)}
                                onChange={() => true}
                                onAccept={(x) => handleExpirationDateChangeUpdate(x, row)}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUpdate}>Cancel</Button>
                        <Button type="submit">Update</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderAddButton = () => {
        return (
            <>                
                <Dialog
                    open={openAdd}
                    onClose={handleCloseAdd}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());                            
                            updateCarrierDetails(formJson, "add");
                            handleCloseAdd();
                        },
                    }}
                >
                    <DialogTitle>Add Carrier</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Fill the fields below.
                        </DialogContentText>
                        <TextField                            
                            inputProps={{ maxLength: 8 }}
                            required
                            margin="dense"
                            id="carrierId"
                            name="carrierId"
                            label="Carrier Id"
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="currencyCode"
                            name="currencyCode"
                            label="Currency Code"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="country"
                            name="country"
                            label="Country Code"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="state"
                            name="state"
                            label="State Code"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            inputProps={{ maxLength: 15 }}
                            required
                            margin="dense"
                            id="amount"
                            name="amount"
                            label="Amount"
                            type="decimal"
                            fullWidth
                            variant="standard"
                        />
                        <InputLabel id="addfreightCode1-label">Freight Code 1</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="addfreightCode1-label"
                            name="freightCode1"
                            id="freightCode1"
                            value={freightCode1}
                            label="Freight Code 1"
                            onChange={handleFreightCode1Change}
                        >
                            {freightHandlingCodeData && freightHandlingCodeData.map((freightCode) => (
                                <MenuItem key={freightCode.code} value={freightCode.code}>{freightCode.code}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel id="addfreightCode2-label">Freight Code 2</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="addfreightCode2-label"
                            id="freightCode2"
                            name="freightCode2"
                            value={freightCode2}
                            label="Freight Code 2"
                            onChange={handleFreightCode2Change}
                        >
                            {freightHandlingCodeData && freightHandlingCodeData.map((freightCode) => (
                                <MenuItem key={freightCode.code} value={freightCode.code}>{freightCode.code}</MenuItem>
                            ))}
                        </Select>
                        <TextField
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="freightCode1Label"
                            name="freightCode1Label"
                            label="Above Code Label"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="freightCode2Label"
                            name="freightCode2Label"
                            label="Below Code Label"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <InputLabel id="showExtraInformation-label">Show Extra Information</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="showExtraInformation-label"
                            id="showExtraInformation"
                            name="showExtraInformation"
                            value={showExtraInformation}
                            label="Show Extra Information"                            
                            onChange={(x)=>handleShowExtraInformationChange(x)}
                        >
                            <MenuItem value={true}>true</MenuItem>
                            <MenuItem value={false}>false</MenuItem>
                        </Select>
                        <FormLabel required sx={{ fontSize: 10 }}>Effective Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                defaultValue={new Date()}
                                value={addEffectiveDateValue}
                                onChange={() => true}
                                onAccept={(x) => handleEffectiveDateChangeAdd(x)}
                            />
                        </LocalizationProvider>
                        <FormLabel required sx={{ fontSize: 10 }}>Expiration Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                defaultValue={new Date()}
                                value={addExpirationDateValue}
                                onChange={() => true}
                                onAccept={(x) => handleExpirationDateChangeAdd(x)}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAdd}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const columns = [
        { field: 'carrierId', headerName: 'Carrier Id', width: 100 },
        { field: 'companyCode', headerName: 'Company Code', width: 100 },
        { field: 'country', headerName: 'Country', width: 50 },
        { field: 'state', headerName: 'State', width: 50 },
        { field: 'currencyCode', headerName: 'Currency', width: 50 },
        { field: 'thresholdAmount', headerName: 'Purchase Threshold', width: 100 },
        { field: 'belowThresholdCode', headerName: 'Below Code', width: 100 },
        { field: 'atExceedsThresholdcode', headerName: 'Above Code', width: 100 },
        { field: 'correspondingLabel1', headerName: 'Above Code Label', width: 150 },
        { field: 'correspondingLabel2', headerName: 'Below Code Label', width: 150 },
        { field: 'showInfo', headerName: 'Show Info', width: 100 },
        { field: 'effectiveDate', headerName: 'Effective Date', width: 100 },
        { field: 'expirationDate', headerName: 'Expiration Date', width: 100 },

        {
            field: 'updateButton',
            headerName: 'Update',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            Update
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true

        },
        {
            field: 'deleteButton',
            headerName: 'Delete',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenDelete(e, params.row)}
                        >
                            Delete
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true
        }
    ];



    return (
        <>
            <Paper sx={{ height: 700, width: '100%' }}>
                <DataGrid
                    slots={{ toolbar: customToolbar }}
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
                    pageSizeOptions={[50, 100]}
                    sx={{ border: 0 }}
                />
            </Paper>
            {openUpdate && renderUpdateButton(selectedRow)}
            {openDelete && renderDeleteButton(selectedRow)}
            {openAdd && renderAddButton() }
        </>
    );
}

export default CarrierDetailAdministration;

