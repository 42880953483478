// User Actions
export const RECEIVED_USERS = 'RECEIVED_USERS';
export const RECEIVE_USER = 'RECEIVE_USER';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const RECEIVED_CUSTOMERS = 'RECEIVED_CUSTOMERS';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const UPDATE_USERFLAGS = 'UPDATE_USERFLAGS';
export const RECEIVED_CUSTOMER_PREFERENCES = 'RECEIVED_CUSTOMER_PREFERENCES';
export const UPDATE_USER_FULL_NAME = 'UPDATE_USER_FULL_NAME';

// Cart Actions
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_ALL_ITEMS = 'UPDATE_ALL_ITEMS';
export const CLEAR_CART = 'CLEAR_CART';
export const ORDER_LOADED = 'ORDER_LOADED';
export const FETCH_PRICE = 'FETCH_PRICE';
export const DROPSHIP_UPDATED = 'DROPSHIP_UPDATED';
export const CLEAR_FETCHING = 'CLEAR_FETCHING';
export const UPDATE_ORDER_HEADER = 'UPDATE_ORDER_HEADER';
export const UPDATE_ORDER_REVIEW = 'UPDATE_ORDER_REVIEW';
export const ITEM_CANNOT_BE_ADDED_TO_CART = 'ITEM_CANNOT_BE_ADDED_TO_CART';
export const IMPORT_CART_ITEMS = 'IMPORT_CART_ITEMS';
export const UPDATE_REPRICE_MESSAGE_DISPLAYED = 'UPDATE_REPRICE_MESSAGE_DISPLAYED';

// Setting Actions
export const LOAD_STOREFRONT_SETTINGS = 'LOAD_STOREFRONT_SETTINGS';
export const LOAD_FRONTEND_SETTINGS = 'LOAD_FRONTEND_SETTINGS';


//Order Actions
export const SET_ORDERHISTORY_FILTERS = 'SET_ORDERHISTORY_FILTERS';
export const RESET_ORDERHISTORY_FILTERS = 'RESET_ORDERHISTORY_FILTERS';