import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Notifications from '../../services/Notifications';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FormLabel } from '@mui/material';

export const NewsAdministration = (props) => {
    const [newsData, setNewsData] = useState([]);
    const [dateValue, setDateValue] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const storeData = useStorefrontSettings();
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const responseMessages = {
        "DuplicateKeyViolation": "This News entry already exists for this storefront. Please enter a unique News entry.",
        "GenericFailure": "An error occurred while attempting to update the News entry. Please try again later.",
        "DeleteSuccess": "News entry successfully deleted.",
        "UpdateSuccess": "News entry successfully updated.",
        "AddSuccess": "News entry successfully added."
    };
    var addDateValue = new Date();
    useEffect(() => {
        getNews();
    }, []);

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' },
                    }}
                />

                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
                {renderAddButton()}
            </GridToolbarContainer>
        );
    }

    const getNews = () => {
        setLoading(true);
        Storefront.getNews(storeData.storefrontNumber).then(loaded);
    }

    const updateNews = async (form, type) => {
        setLoading(true);
        var useDate = type==="add" ? addDateValue : dateValue;
        var request = {
            id: form.id ?? null,
            title: form.title ?? null,
            content: form.content ?? null,
            postDate: useDate ?? null,
            priority: Number(form.priority) ?? null,
            storefrontNumber: storeData.storefrontNumber,
            operationType: type
        };
        addDateValue = new Date();
        return await Storefront.updateNews(request).then((res) => {
            if (res) {
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error("An error occurred while attempting to modify or add the news entry. Please try again later.");
                }
                getNews();
            }
        });
    }

    const loaded = (data) => {
        if (data) {
            setNewsData(data)
            mapRows(data);
        }
        else {
            setNewsData({})
        }
        setLoading(false)
    }

    const mapRows = (data) => {
        var rows = [];
        data.news.forEach((newsEntry) => {

            rows.push({
                id: newsEntry.id,
                title: newsEntry.title,
                content: newsEntry.content,
                postDate: newsEntry.postDate,
                priority: newsEntry.postPriority,
                lastModifiedBy: newsEntry.lastModifiedBy,
                lastModifiedByDate: newsEntry.lastModifiedByDate,
                firstName: newsEntry.modifyUserFirstName,
                lastName: newsEntry.modifyUserLastName,
            });
        });
        setRows(rows);
    }

    const handleClickOpenUpdate = (e, row) => {
        e.stopPropagation();
        setDateValue(row.postDate);
        setSelectedRow(row);
        setOpenUpdate(true);
    };

    const handleDateChangeUpdate = (e, row) => {
        setDateValue(e);
    }

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        setDateValue(new Date());
    };

    const handleClickOpenDelete = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleClickOpenAdd = (e) => {
        e.stopPropagation();
        setOpenAdd(true);
    };

    const handleDateChangeAdd = (e) => {
        addDateValue=e;
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
        addDateValue = new Date();
    };

    const renderDeleteButton = (row) => {
        return (
            <>
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            formData.append("priority", row.priority);
                            const formJson = Object.fromEntries(formData.entries());
                            updateNews(formJson, "delete");
                            handleCloseDelete();
                        },
                    }}
                >
                    <DialogTitle>Are You Sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this news entry?
                        </DialogContentText>
                        <DialogContentText ><b>{row.code}</b></DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete}>Cancel</Button>
                        <Button type="submit">Delete</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderUpdateButton = (row) => {
        return (
            <>
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            const formJson = Object.fromEntries(formData.entries());
                            updateNews(formJson, "update");
                            handleCloseUpdate();
                        },
                    }}
                >
                    <DialogTitle>Update News Entries</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Below are the values that may be updated.
                        </DialogContentText>
                        <TextField
                            defaultValue={row.title}
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="title"
                            name="title"
                            label="Title"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            defaultValue={row.content}
                            required
                            margin="dense"
                            id="content"
                            name="content"
                            label="Content"
                            type="text"
                            fullWidth
                            variant="standard"
                        />                        
                        <TextField
                            defaultValue={row.priority}
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="priority"
                            name="priority"
                            label="Priority"
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <FormLabel required sx={{ fontSize: 10 }}>Post Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                defaultValue={new Date(row.postDate)}
                                value={new Date(dateValue)}
                                onChange={() => true}
                                onAccept={(x) => handleDateChangeUpdate(x, row)}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUpdate}>Cancel</Button>
                        <Button type="submit">Update</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const renderAddButton = () => {
        return (
            <>
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={handleClickOpenAdd}
                    >
                        Add
                    </Button>
                </strong>
                <Dialog
                    open={openAdd}
                    onClose={handleCloseAdd}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            updateNews(formJson, "add");
                            handleCloseAdd();
                        },
                    }}
                >
                    <DialogTitle>Add News</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Fill the required fields below.
                        </DialogContentText>
                        <TextField
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="title"
                            name="title"
                            label="Title"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            required
                            margin="dense"
                            id="content"
                            name="content"
                            label="Content"
                            type="text"
                            fullWidth
                            variant="standard"
                        />               
                        <TextField
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="priority"
                            name="priority"
                            label="Priority"
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <FormLabel required sx={{ fontSize: 10 }}>Post Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                defaultValue={new Date()}
                                required
                                value={addDateValue}
                                onChange={() => true}
                                onAccept={(x) => handleDateChangeAdd(x)}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAdd}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }    
    const columns = [
        { field: 'title', headerName: 'Title', width: 100 },
        { field: 'content', headerName: 'Content', width: 250 },
        { field: 'priority', headerName: 'Priority', width: 50 },
        { field: 'lastModifiedBy', headerName: 'Last Modified By', width: 200 },
        { field: 'postDate', headerName: 'Post Date', width: 150 },                
        { field: 'lastModifiedByDate', headerName: 'Last Modification', width: 150 },
        {
            field: 'updateButton',
            headerName: 'Update',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            Update
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true

        },
        {
            field: 'deleteButton',
            headerName: 'Delete',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenDelete(e, params.row)}
                        >
                            Delete
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true
        }
    ];



    return (
        <>
            <Paper sx={{ height: 700, width: '100%' }}>
                <DataGrid
                    slots={{ toolbar: customToolbar }}
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
                    pageSizeOptions={[50, 100]}
                    sx={{ border: 0 }}
                />
            </Paper>
            {openUpdate && renderUpdateButton(selectedRow)}
            {openDelete && renderDeleteButton(selectedRow)}
        </>
    );
}

export default NewsAdministration;

