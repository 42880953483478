import { useMemo, useCallback } from "react";
import classnames from "classnames";
import ActionButton from "../../shared/button/ActionButton";

const AddOrUpdateButton = ({
  isEditMode,
  editLocalClaimItem,
  addLocalClaimItem,
  localized,
  canAdd,
  loading,
}) => {
  const isDisabled = canAdd === false;

  const buttonText = useMemo(
    () => (isEditMode ? localized.UpdateItem : localized.AddItem),
    [isEditMode, localized.AddItem]
  );

  const dataCy = useMemo(
    () => (isEditMode ? "claim-updateItem" : "claim-addItem"),
    [isEditMode]
  );

  const buttonOnClick = useCallback(
    () => (isEditMode ? editLocalClaimItem() : addLocalClaimItem()),
    [isEditMode, editLocalClaimItem, addLocalClaimItem]
  );

  const buttonType = classnames({
    "gray-outline": isDisabled,
    "green-outline": !isDisabled,
  });

  return (
    <ActionButton
      text={buttonText}
      variant={buttonType}
      onClick={buttonOnClick}
      dataCy={dataCy}
      disabled={isDisabled}
      tabIndex={isDisabled ? -1 : 0}
      loading={loading}
    />
  );
};

export default AddOrUpdateButton;
