 import initialState from './initialState';
import * as actions from '../actions/actionTypes';

export default function usersReducer(state = initialState.user, action) {

    let newState = null;
    switch (action.type) {
        case actions.RECEIVED_USERS:
            newState = {
                ...state,
                filteredUsers: action.filteredUsers,
                selectedUser: (state.selectedUser != null) ? action.filteredUsers.find((u) => { return u.id === state.selectedUser.id; }) : null,
            };
            break;
        case actions.RECEIVE_USER:
            newState = {
                ...state,
                currentUser: action.user,
                isAuthorized: action.user != null
            };
            break;
        case actions.SET_SELECTED_USER:
            let allUsers = state.filteredUsers;
            const userIndex = allUsers.findIndex((u) => { return u.id === action.user.id; });
            if (userIndex > 0) { // updates user when editing
                allUsers[userIndex] = action.user;
            }
            newState = {
                ...state,
                filteredUsers: allUsers,
                selectedUser: action.user
            };
            break;
        case actions.RECEIVED_CUSTOMERS:
            newState = {
                ...state,
                customers: action.customers
            };
            break;
        case actions.SET_CUSTOMER:
            newState = {
                ...state,
                selectedCustomer: action.customer
            };
            break;
        case actions.UPDATE_USERFLAGS:
            let updatedUser = state.currentUser;
            updatedUser.userFlags = action.userFlags;
            newState = {
                ...state,
                currentUser: updatedUser
            };
            break;
        case actions.RECEIVED_CUSTOMER_PREFERENCES:
            let selectedCustomer = {
                ...state.selectedCustomer,
                freightHandlingCode: action.preferences.freightHandlingCode,
                paymentInstrument: action.preferences.paymentInstrument,
                paymentTermId: action.preferences.paymentTermsAR,
                customerPORequired: action.preferences.customerPORequired,
            }
            newState = {
                ...state,
                selectedCustomer: selectedCustomer
            }
            break;
        case actions.UPDATE_USER_FULL_NAME:
            const { currentUser, selectedUser } = state;
            const { isAdmin, firstName, lastName, userId } = action.user;

            if (isAdmin || (selectedUser && selectedUser.id === userId)) {
                selectedUser.firstName = firstName;
                selectedUser.lastName = lastName;

                newState = {
                    ...state,
                    selectedUser: selectedUser,
                }
            }

            if (currentUser.id === userId) {
                currentUser.firstName = firstName;
                currentUser.lastName = lastName;

                newState = {
                    ...state,
                    currentUser: currentUser,
                }
            }
        default:
            newState = state;
            break;
    }
    return newState;
}

// immutability-helper

// https://stackoverflow.com/questions/44167003/actions-must-be-plain-objects-use-custom-middleware-for-async-actions-lost-he